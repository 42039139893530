.showcaseRow a{
    all: unset;
    cursor: pointer;
}

.showcaseRow{
    padding: 34px 0 0;
    display: flex;
    flex-direction: column;
}

.page-content .contentWrapper div:has(.showcaseRow):last-child .showcaseRow{
    padding-bottom: 34px;
}

.sR-titleRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
}

.sR-titleRow-title h2{
    font-family: 'BeelineSans';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    color: #000000;
}

.sR-shelf .sR-contentRow, .sR-shelfBig .sR-contentRow, .sR-shelfAudioBig .sR-contentRow, .sR-showcases .sR-contentRow, .sR-shelfAnimatedAudio .sR-contentRow{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 30px;
    align-items: flex-start;
}

.showcaseRow.sR-shelfBig .sR-additContentRow, .showcaseRow.sR-shelfAudioBig .sR-additContentRow{
    margin-top: 20px;
}

.sR-shelfBig .sR-contentRow-left, .sR-shelfAudioBig .sR-contentRow-left{
    display: flex;
    flex: 2;
    aspect-ratio: 100 / 143;
    position: relative;
    height: fit-content;
}

.sR-shelfAudioBig .sR-contentRow-left{
    aspect-ratio: 1 / 1;
}

.sR-shelfBig .sR-contentRow-right, .sR-shelfAudioBig .sR-contentRow-right{
    display: flex;
    flex: 9 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
}

.sR-shelfBig .sR-contentRow-left img, .sR-shelfAudioBig .sR-contentRow-left>a>img{
    width: 100%;
    aspect-ratio: 100 / 143;
    background: linear-gradient(134.36deg, #FAFCFE 1.1%, #D5E4F0 100%);
    border-radius: var(--bigbook-border-radius);
    border: 1px solid #efefef;
    box-sizing: border-box;
}

.sR-shelfAudioBig .sR-contentRow-left>a>img{
    aspect-ratio: 1 / 1;
    border-radius: 8px;
}

.sR-shelfAudioBig .sR-contentRow-left a:has(.adultMask) .content-cover-vinil{
    display: none;
}

.sR-shelfBig .sR-additContentRow, .sR-shelfAudioBig .sR-additContentRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.sR-shelfBig .sR-additContentWrapper, .sR-shelfAudioBig .sR-additContentWrapper{
    width: 93%;
    background: rgba(193, 207, 214, .5);
    border-radius: 0 10px 10px 10px;
    backdrop-filter: blur(5px);
    padding: 19px;
}

.sR-shelfBig .sR-additContent-titleRow, .sR-shelfAudioBig .sR-additContent-titleRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 30px;
}

.sR-shelfBig .sR-additContent-titleRow-left, .sR-shelfAudioBig .sR-additContent-titleRow-left{
    display: flex;
    flex-direction: column;
    flex: 7;
}

.sR-shelfBig .sR-additContent-titleRow-title, .sR-shelfAudioBig .sR-additContent-titleRow-title{
    font-family: 'BeelineSans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 10px;    
}

.sR-shelfBig .sR-additContent-titleRow-author, .sR-shelfAudioBig .sR-additContent-titleRow-author{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;    
}

.sR-shelfBig .sR-additContent-contentRow, .sR-shelfAudioBig .sR-additContent-contentRow{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin-top: 15px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: #212529;    
}

.sR-additContentRow-tail{
    height: 40px;
    width: 40px;
    position: absolute;
    top: -40px;
    left: 0;
    border-radius: 0 0 0 0;
    /* background: rgba(193, 207, 214, .5); */
    /* backdrop-filter: blur(5px); */
}

.sR-additContentRow-tail::after{
    content: '';
    position: absolute;
    left: 0px;
    bottom: 0px;
    border: 25px solid transparent;
    border: 25px solid transparent;
    border-bottom: 25px solid rgba(193, 207, 214, .5);
    border-left: 25px solid rgba(193, 207, 214, .5);
    backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);    
}

.showcaseRow.sR-banner{
    width: 100%;
    cursor: pointer;
}

.showcaseRow.sR-banner img{
    width: 100%;
}

.sR-showcases-item{
    height: 122px;
    flex: 1;
    position: relative;
    background-size: cover;
    border-radius: 32px;
}

.sR-showcases a:has(.sR-showcases-item){
    flex: 1;
}

.sR-showcases .sR-showcases-title{
    position: absolute;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 0 75px;
    align-items: center;
    color: #fff;
    font-family: 'BeelineSans';
    font-size: 30px;
    font-weight: 500;
    line-height: 30px;
    white-space: pre-wrap;
}

.showcaseRow.sR-book.sR-book-wrapper, .showcaseRow.sR-audiobook.sR-book-wrapper{
    width: 100vw;
    min-width: var(--main-content-width);
    background: #F1F6F9;
    margin-top: 34px;
    padding-bottom: 34px;
    display: flex;
    align-items: center;
}

.sR-book .content, .sR-audiobook .content{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 35px
}

.sR-book .content .content-cover, .sR-audiobook .content .content-cover{
    flex: 3;
    height: fit-content;
    position: relative;
}

.sR-audiobook .content .content-cover{
    margin-top: 27px;
}

.sR-book .content .content-cover>img, .sR-audiobook .content .content-cover>img{
    width: 100%;
    border-radius: 15px;
    aspect-ratio: 100 / 143;
    background: linear-gradient(134.36deg, #FAFCFE 1.1%, #D5E4F0 100%);
    border-radius: 8px;
    border: 1px solid #efefef;
    box-sizing: border-box;
}

.sR-audiobook .content .content-cover>img{
    aspect-ratio: 1 / 1;
}

.sR-audiobook .content-cover:has(.adultMask) .content-cover-vinil{
    display: none;
}

.sR-book .content .content-data, .sR-audiobook .content .content-data{
    flex: 10;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
}

.sR-book .badge, .sR-audiobook .badge{
    height: 88px;
    border-radius: 10px;
    background: linear-gradient(90deg, rgba(0, 205, 193, .6) 0%, rgba(0, 255, 133, .6) 100%);
    backdrop-filter: blur(5px);
    min-width: 336px;
    white-space: nowrap;
    padding: 0px 50px 0 33px;
    font-family: 'BeelineSans';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 88px;
    color: #FFFFFF;
    text-align: center;
    margin: 27px 0 27px -50px;
    text-overflow: ellipsis;
    max-width: 600px;
    overflow: hidden;
}

.sR-audiobook .badge{
    margin-top: 0;
}

.sR-book .badge:after, .sR-audiobook .badge:after {
    content: '';
    display: block;
    border: 40px solid transparent;
    border-right-color: #f1f6f9;
    position: absolute;
    bottom: 4px;
    right: -5px;
}

.sR-book .badge.red, .sR-audiobook .badge.red{
    background: linear-gradient(90deg, rgba(181, 95, 189, 0.7) 0%, rgba(234, 35, 35, 0.7) 100%);
}

.sR-book .content-title, .sR-audiobook .content-title{
    font-family: 'BeelineSans';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    color: #000000;
}

.sR-book .content-author, .sR-audiobook .content-author{
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin-top: 5px;
}

.sR-book .content-annotation, .sR-audiobook .content-annotation{
    margin-top: 11px;
    font-family: 'BeelineSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: #212529;    
}

.sR-book .content-categories, .sR-audiobook .content-categories{
    width: 100%;
    margin-top: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 13px;
}

.sR-book .content-categories-item, .sR-audiobook .content-categories-item{
    float: left;
    padding: 3px 10px;
    border-radius: 30px;
    background: #fff;
}

.sR-book .content-buttonsArea, .sR-audiobook .content-buttonsArea{
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-top: 30px;
}

.contentWrapper .showcaseRow:first-child:is(.sR-book), .contentWrapper .showcaseRow:first-child:is(.sR-audiobook){
    margin-top: 0;
}

.sR-audiobook .content-cover{
    position: relative;
}

.sR-audiobook .content-cover-vinil, .sR-shelfAudioBig .content-cover-vinil{
    position: absolute;
    width: 13%;
    top: 40%;
    right: -4%;
    aspect-ratio: 37 / 74;
}

.sR-audiobook .content-cover-vinil img, .sR-shelfAudioBig .content-cover-vinil img{
    width: 100%;
    height: 100%;
}

.showcaseRow.sR-shelfAnimatedAudio{
    align-items: center;
    width: 100%;
    max-width: var(--main-content-width);    
}

.sR-shelfAnimatedAudio .sR-contentRow{
    width: 100vw;
    min-width: var(--main-content-width);
}

.sR-shelfAnimatedAudio .sR-titleRow-href{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 30px;
    align-items: flex-end;
}

.SwiperCentered-next, .SwiperCentered-prev{
    font-size: 25px;
    line-height: 25px;
    cursor: pointer;
}