.sR-contentRow:has(.bookContainerWrapperRotation){
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 530px;
    /* display: grid;
    overflow: hidden;
    grid-auto-rows: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 530px;
    grid-auto-flow: column;
    justify-content: center;
    grid-auto-columns: calc(33% - 17px);     */
}

.bookContainerStyleRotation{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.bookContainerStyleRotation-cover{
    width: 100%;
    position: relative;
    aspect-ratio: 100/143;
    height: fit-content;
    box-shadow: 14px 17px 21px 0px rgba(35, 47, 65, 0.20);
    width: 60%;
    margin: 0 auto;
    margin-bottom: 30px;
    box-sizing: border-box;
    border-radius: 3px;
    overflow: hidden;
}

.bookContainerStyleRotation-cover>img{
    width: 100%;
    aspect-ratio: 100 / 143;
    border-radius: 4px;
    box-sizing: border-box;
}

.bookContainerStyleRotation-cover:has(.adultMask) .bookContainerStyleRotation-cover-vinil{
    display: none;
}

.bookContainerWrapperRotation{
    flex: 1;
    width: 100%;
    max-width: 100%;
    padding: 24px 32px;
    box-sizing: border-box;
    border: 1px solid #f1f1f1;
    border-radius: 16px;
    height: 100%;
    display: flex;
}

.bookContainerStyleRotation .bookContainerStyleRotation-title{
    margin-top: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #191C22;
    font-family: BeelineSans;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    overflow: hidden; 
}

.bookContainerStyleRotation .bookContainerStyleRotation-author{
    margin-top: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #727281;
    font-family: BeelineSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.bookContainerStyleRotation.audioBook .bookContainerStyleRotation-cover{
    aspect-ratio: 1/1;
}

.bookContainerStyleRotation.audioBook .bookContainerStyleRotation-cover>img{
    aspect-ratio: 1/1;
}

.bookContainerStyleRotation.audioBook .bookContainerStyleRotation-cover-vinil{
    position: absolute;
    width: 13%;
    top: 40%;
    right: -5%;
    aspect-ratio: 37 / 74;
}

.bookContainerStyleRotation.audioBook .bookContainerStyleRotation-cover-vinil img{
    width: 100%;
    height: 100%;
}